import React, { Component } from "react";
import { Page, Font, Text, Document, StyleSheet } from "@react-pdf/renderer";
// This little library is not supported for a while now
import { PDFViewer } from "@react-pdf/renderer";

class MainComponent extends Component {
  constructor(props) {
    super(props);
    this.styles = StyleSheet.create({
      body: {
        paddingTop: 35,
        paddingBottom: 65,
        paddingHorizontal: 35,
      },
      title: {
        fontSize: 24,
        textAlign: "center",
        fontFamily: "Roboto",
        fontWeight: "bold",
        padding: 2,
        border: 1,
      },
      author: {
        fontSize: 12,
        textAlign: "center",
        marginBottom: 40,
        marginTop: 10,
      },
      text: {
        marginTop: 2,
        margin: 12,
        fontSize: 14,
        textAlign: "justify",
        fontFamily: "Roboto",
      },

      header: {
        fontSize: 12,
        marginBottom: 20,
        textAlign: "center",
        color: "grey",
      },
      booktitle: {
        fontSize: 28,
        marginTop: 65 + `%`,
        color: "black",
        textAlign: "center",
        fontFamily: "Roboto",
        padding: 2,
        fontWeight: "bold",
      },
      booktitleHeader: {
        fontSize: 20,
        marginTop: 10 + `%`,
        color: "black",
        textAlign: "center",
        fontFamily: "Roboto",
      },
      dateHeader: {
        fontSize: 18,
        marginTop: 10 + `%`,
        color: "black",
        textAlign: "center",
      },
      viewBorder: {
        border: 1,
      },
      pageNumber: {
        position: "absolute",
        fontSize: 12,
        bottom: 30,
        left: 0,
        right: 0,
        textAlign: "center",
        color: "grey",
      },
    });
    this.loadingBarstyle = {
      width: 100 + `%`,
    };
    this.pdfViewerstyle = {
      width: 100 + `vw`,
      height: 100 + `vh`,
    };
    this.state = {
      pdfdata: null,
      loading: null,
      pdfLength: null,
      bookResult: null,
      author: `artefaktas.eu`,
      bookTitle: `artefaktas.eu`,
    };
    this.appSettings = {
      createdBy: `Created with artefaktas.eu tool`,
    };
    this.generatePDF = this.generatePDF.bind(this);
    this.bookApi = `https://feedreader.artefaktas.workers.dev/feeds`;
    this.feedReader = `https://feedreader.artefaktas.workers.dev/feed`;
    Font.register({
      family: "Roboto",
      src: "https://cdnjs.cloudflare.com/ajax/libs/ink/3.1.10/fonts/Roboto/roboto-light-webfont.ttf",
    });
    this.nd = new Date();
    this.year = this.nd.getUTCFullYear();
    this.month = this.nd.getUTCMonth();
    if (this.month < 10) {
      this.month = `0${String(this.month)}`;
    }
    this.day = this.nd.getUTCDate();
    if (this.day < 10) {
      this.day = `0${String(this.day)}`;
    }
    this.todayIs = `${this.year}-${this.month}-${this.day}`;
  }

  componentDidMount() {
    const bookLink = this.bookApi;
    async function getLinks() {
      const res = await fetch(bookLink);
      const jsonDat = await res.json();
      return jsonDat;
    }
    getLinks().then((bkres) => {
      this.setState({ bookResult: bkres });
    });
  }

  generatePDF() {
    const fetchUrl = this.feedReader;

    async function transformText(passedCont) {
      let newDiv = document.createElement("div");
      newDiv.innerHTML = passedCont;
      let text = newDiv.innerText;
      newDiv.innerHTML = "";
      newDiv.innerHTML = text;
      text = newDiv.innerText;
      let textFields = text.split(". ");
      let newRow = "";
      textFields.forEach((element) => {
        if (
          String(element).charAt(0).toUpperCase() === String(element).charAt(0)
        ) {
          element = `\n\n${element}.`;
          newRow = newRow + element;
        } else {
          newRow = newRow + `${element}.`;
        }
      });
      return newRow;
    }
    async function getJsonData() {
      const selectedBookLink = document.querySelector("#selectField").value;
      const res = await fetch(fetchUrl, {
        headers: {
          passed_url: selectedBookLink,
          "User-Agent": "artefaktasbot",
        },
      })
        .then((r) => r.text())
        .then((finalRes) => {
          return finalRes;
        });
      const xml = await fetch(res);
      const rssData = await xml.text();
      const rssEl = await new window.DOMParser().parseFromString(
        rssData,
        "text/xml"
      );
      const items = rssEl.querySelectorAll("item");
      const itemArr = [];
      items.forEach((el) => {
        transformText(el.querySelector("description").innerHTML).then((res) => {
          let ob = {};
          ob.title = el.querySelector("title").innerHTML;
          ob.description = res;
          itemArr.push(ob);
        });
      });
      return itemArr;
    }
    this.setState({ loading: true }, () => {
      getJsonData().then((res) => {
        this.setState({ pdfdata: res });
      });
    });
  }

  render() {
    return (
      <div className="container-fluid">
        <div className="row">
          <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12 mx-auto my-4 text-center">
            <select
              className="form-control form-control-lg my-4"
              id="selectField"
              onChange={(e) => {
                this.setState({
                  author:
                    e.currentTarget.selectedOptions[0].getAttribute(
                      "data-author"
                    ),
                  bookTitle:
                    e.currentTarget.selectedOptions[0].getAttribute(
                      "data-booktitle"
                    ),
                  pdfdata: null,
                  loading: null,
                });
              }}
            >
              {this.state.bookResult !== null
                ? this.state.bookResult.map((bk, i) => {
                    if (i === 0) {
                      return (
                        <option
                          key={i}
                          value={bk.Url}
                          data-author={bk.Author}
                          data-booktitle={bk.Title}
                          defaultValue
                        >
                          {bk.Url}
                        </option>
                      );
                    } else {
                      return (
                        <option
                          key={i}
                          value={bk.Url}
                          data-author={bk.Author}
                          data-booktitle={bk.Title}
                        >
                          {bk.Url}
                        </option>
                      );
                    }
                  })
                : ""}
            </select>
            <button
              className="btn btn-lg btn-dark mx-auto"
              onClick={this.generatePDF}
            >
              Generate PDF
            </button>
          </div>
          <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12 mx-auto my-4">
            <div className="row">
              {this.state.loading === true ? (
                <div className="progress">
                  <div
                    className="progress-bar progress-bar-animated progress-bar-striped bg-success"
                    role="progressbar"
                    style={this.loadingBarstyle}
                    aria-valuenow="100"
                    aria-valuemin="0"
                    aria-valuemax="100"
                  ></div>
                </div>
              ) : (
                ""
              )}
              {this.state.pdfdata !== null ? (
                <PDFViewer style={this.pdfViewerstyle}>
                  <Document>
                    <Page style={this.styles.body} size="A4">
                      <Text style={this.styles.booktitle} fixed>
                        {this.state.bookTitle}
                      </Text>
                      <Text style={this.styles.booktitleHeader} fixed>
                        By: {this.state.author}
                      </Text>
                      <Text style={this.styles.booktitleHeader} fixed>
                        {this.todayIs}
                      </Text>
                    </Page>
                    {this.state.pdfdata.map((post, i) => {
                      let text = post.description;
                      return (
                        <React.Fragment key={i}>
                          <Page style={this.styles.body}>
                            <Text style={this.styles.booktitle} fixed>
                              {post.title}
                            </Text>
                          </Page>
                          <Page style={this.styles.body}>
                            <Text style={this.styles.header} fixed>
                              {this.appSettings.createdBy}
                            </Text>
                            <Text style={this.styles.title}>{post.title}</Text>
                            <Text style={this.styles.text}>{text}</Text>
                            <Text style={this.styles.author}>
                              Author: {this.state.author}
                            </Text>
                            <Text style={this.styles.pageNumber} fixed>
                              {`${i + 1} iš ${this.state.pdfdata.length}`}
                            </Text>
                          </Page>
                        </React.Fragment>
                      );
                    })}
                  </Document>
                </PDFViewer>
              ) : (
                ""
              )}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default MainComponent;
